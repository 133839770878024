import { useEnqueueToast } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { getHubspotContactLink } from '../hubspot-util'
import { openLinkInNewWindow } from '../window-util'

export type OpenHubspotContact = { openHubspotContact: (email: string | undefined) => void }

export function useOpenHubspotContact(): OpenHubspotContact {
  const { enqueueToast } = useEnqueueToast()

  const openHubspotContact = useCallback(
    (email: string | undefined) => {
      if (!email) {
        enqueueToast(
          "Il n'y a pas d'email associé à ce devis. Cette fonctionnalité n'est disponible que lorsqu'un email a été saisi",
          {
            variant: 'info',
          },
        )
        return
      }
      const url = getHubspotContactLink(email)
      const errorMessage = openLinkInNewWindow(url)
      if (errorMessage) {
        enqueueToast(errorMessage, {
          variant: 'danger',
        })
      }
    },
    [enqueueToast],
  )

  return { openHubspotContact }
}
